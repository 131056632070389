@import "./styles/variables";
@import "./styles/bootstrap";
@import "./styles/bc-theme-2024.scss";

/**START: bootstrap styles that are outside of _bootstrap.scss
* This is because reboot gets loaded multiple times.
**/
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/reboot";
/**END: bootstrap styles that are outside of _bootstrap.scs**/

/**Specificity is unnecessary here BUT, without html in selector,
this style gets mapped to _reboot file
**/
html body {
  color: $primary-body-color;
  font-weight: 100;
  //padding-top: 125px; __<<ngThemingMigrationEscapedComment3>>__
  padding-top: 100px; /*push body down for fixed header*/
  background-color: white;
  font-size: 0.875rem;
  font-size: 16px;
  line-height: 1.428571429;
}

.routerOutlet{
  min-height: 55vh;
  height: 100%;
}

/*html {
  position: relative;
  min-height: 100%;
}

footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: #f5f5f5;
}*/

h1, h2, h3, h4, h5, p, li, button, .h3, .h4, .h5 {
  font-family: 'Lato', sans-serif;
  text-shadow: none !important;
  color: $primary-body-color;

}
h1, h2, h3, h4, h5, h6, .h3, .h4, .h5, .h6 {
  font-weight: 500;
  line-height: 1.1;
  text-shadow: none !important;
}
h1{
  font-size: 36px;
}
h2{
  font-size: 30px;
}
h3, .h3 {
  font-size: 24px;
}
h4, .h4 {
  font-size: 18px;
}
h5, .h5 {
  font-size: 14px;
}
h6, .h6{
  font-size: 12px;
}
p {
  margin: 0 0 10px;
  font-weight: 400;
}

h1, h2, h3, .h3 {
  margin-top: 20px;
  margin-bottom: 10px;
}
h4, h5, h6, .h6, .h4, .h5 {
  margin-top: 10px;
  margin-bottom: 10px;
}
a{
  font-family: 'Lato', sans-serif;
  text-shadow: none !important;
  color: $hyperlink-blue;

  :hover {
    color: $hyperlink-hover-blue
  }
;
}
.btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.428571429;
  border-radius: 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}
.balance-btn {
  background-color: #90c740;
  color: #ffffff;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
  font-size: 16px;
  font-family: 'Lato', sans-serif;
  text-shadow: none !important;
  text-transform: uppercase;
}

button.orange, button.orange:hover, .open .dropdown-toggle.btn-default, .button.orange, .button.orange:hover {
  background: #ef662f;
  color: #fff !important;

  &.inverse {
    border: 2px solid #ef662f;
    background: #fff;
    color: #ef662f !important;
  }
}

button.orange-apply, button.orange-apply:hover {
  background: linear-gradient(90.61deg, #F26522 3.26%, #FF9900 99.47%);
  border: none;
  border-radius: 10px;
}

button.gray {
  border: 2px solid #eae9e9;
  background: #fff;
  color: #969191;
}

mat-radio-group {
  &.radio-group-vertical {
    display: flex; 
    flex-direction: column;
  }
}

mat-form-field{
  width:100%
}

.balance-dark-blue {
  color: $text-dark-blue;
}

.grey-text {
  color: $balance-grey-text;
}

.balance-green{
  color: $balance-green;
}

.header-not-bold{
  font-weight: 100;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: $balance-green !important;
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: $balance-green !important;
}
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
  background-color: rgba($balance-green, .5) !important;
}

.balance-grey-bg {
  background: #f2f3f4;
}

.mat-form-field-appearance-outline .mat-form-field-outline{
  background-color: white;
  border-radius: 5px;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background, .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-accent .mat-pseudo-checkbox-checked, .mat-accent .mat-pseudo-checkbox-indeterminate, .mat-pseudo-checkbox-checked, .mat-pseudo-checkbox-indeterminate {
  background-color: #8EC641 !important;
}

.mat-checkbox-label {
  color: #808080 !important;
  font-weight: normal !important;
}

.credit-pull-auth-panel .mat-dialog-container {
  padding: 15px;

  .graph-container {
    margin-top: 20px;
  }
}

.widgetHeightMaxHeight {
  min-height: 400px;
  max-height: 400px;
}

// *** Apply Site Styles -- Start *** 
.bc-theme-2024 {

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 0;
    margin-bottom: 8px;
    color: $balance-apply-dark-blue;
  }

  p {
    font-size: 16px;
  }

  .col-12 {
    padding: 0;
  }

  // *** Form header blue styles ***
  .form-title-text {
    text-align: start;
    color: $balance-white !important;
    font-weight: bold;
    font-size: 45px;

    .secondary {
      color: $balance-green;
    }
  }

  .form-subtitle-text {
    text-align: start;
    color: #D9D9D980;
    margin: 0;
    margin-top: 8px;
    font-size: 25px;

    br {
      display: none;
    }
  }

  .mobile-content {
    display: none;
  }

  .desktop-content {
    display: block;
  }
}

@media (max-width: $md) {
  .bc-theme-2024 {
    p {
      font-size: 13px;
    }

    // *** Form header blue styles ***
    .form-title-text {
      text-align: center;
      font-size: 5.5vw;
      margin: 0;
    }

    .form-subtitle-text {
      text-align: center;
      font-weight: bold;
      font-size: 13px;

      br {
        display: block;
      }
    }

    .mobile-content {
      display: block;
    }

    .desktop-content {
      display: none;
    }
  }
}


// *** Apply Site Styles -- End *** 

@include media-breakpoint-up(sm) {
  .credit-pull-auth-panel {
    max-height: 600px !important;
    max-width: 600px !important;

    .mat-dialog-container {
      .graph-container {
        margin-top: 25px;
      }
    }

    #patriotAct{
      max-height: 55%;
    }

    #balanceCreditDataSharing{
      max-height: 50%;
    }

  }
}


/*# sourceURL=bc-styles.scss */
