$primary-accent-color:        #11A7BD;
$primary-body-color:          #333333;;
$primary-header-font-weight:  200;
$text-dark-blue:              #304859;
$balance-green:               #8EC641;
$balance-orange:              #ef662f;
$balance-light-gray:          #e7e7e7;
$hyperlink-blue:              #428bca;
$hyperlink-hover-blue:        #2a6496;
$balance-grey-text:           #6E6E6E;

//Apply Site specific style variables
$md: 768px; 
$balance-white:               #FFFFFF;
$balance-apply-orange:        #F26522;
$balance-apply-light-orange:  #FF9900;
$balance-apply-dark-gray:     #585858;
$balance-apply-dark-blue:     #2C4557;
