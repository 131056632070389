@import './bootstrap';

// *** Apply Site Styles *** 
$primary-accent-color: #11A7BD;
$primary-body-color: #333333;
$balance-dark-blue: #2C4557;
$balance-green: #8EC641;
$balance-orange: #F26522;
$balance-black: #000000;
$balance-white: #FFFFFF;
$balance-light-orange: #FF9900;
$balance-dark-gray: #585858;
$balance-light-gray: #D9D9D9;
$hyperlink-blue: #428bca;
$hyperlink-hover-blue: #2a6496;
$balance-grey-text: #6E6E6E;

.bc-theme-2024 {
    // *** Buttons - Start *** 
    .mat-button-base {
        font-family: 'Lato', sans-serif;
        font-weight: bold;
        border-radius: 10px;
        &:focus {
            outline: none;
        }
    }

    .mat-flat-button.mat-primary:not(:disabled) {
        color: $balance-white;
        background: linear-gradient(90.61deg, $balance-orange 3.26%, $balance-light-orange 99.47%);
        :hover {
            color: $balance-white;
        }
    }

    .mat-stroked-button.mat-primary:not(:disabled) {
        color: $balance-orange;
        background: $balance-white;
        border: 1px solid $balance-orange;
        :hover {
            color: $balance-orange;
        }
    }
    // *** Buttons - End *** 

    // *** Inputs - Start *** 
    .mat-form-field {
        width: 100%;
    }

    .mat-form-field-underline {
        display: none;
    }

    .mat-form-field-wrapper {
        font-family: 'Lato', sans-serif;
        border: 1px solid $balance-dark-gray;
        border-radius: 10px;
        padding: 0.7rem 1.5rem !important;
    }

    .mat-focused .mat-form-field-label,
    .mat-form-field-label {
        color: $balance-dark-blue !important;
    }

    .mat-form-field-infix {
        border-top: 0;
    }

    .mat-form-field-optional {
        .mat-form-field-wrapper {
            border: none;
            background-color: #E4E4E44D;

            .mat-focused .mat-form-field-label,
            .mat-form-field-label {
                color: $balance-dark-gray !important;
            }
        }
    }

    .input-error {
        .mat-form-field-wrapper {
            border-color: red;
            color: red;
        }

        .mat-focused .mat-form-field-label,
        .mat-form-field-label {
            color: red !important;
        }
    }
    // *** Inputs - End *** 

    .mobile-content {
        display: block;
    }

    .desktop-content {
        display: none;
    }

    .blue-header-container {
        background-color: $balance-dark-blue;
        padding: 25px 0;
        width: 100%;

        .h3 {
            color: white;
            font-size: 20px;
            margin: 0;
            font-weight: 600;
        }
    }

    .custom-container {
        background-color: #F2F6F8;
        max-width: 100% !important;
        padding: 2rem 0.5rem;
    }

    .custom-mat-card-container {
        mat-card {
            border: 1px solid #ced4db;
            box-shadow: none !important;
            padding: 0;
            overflow: hidden;
            border-radius: 18px;
        }
    }

    .icon-button-container {
        .mat-button-base {
            width: 100%;
            height: 50px;
            font-size: 1rem;
        }
        .mat-icon {
            transform: rotate(180deg) !important;
            line-height: normal;
        }
    }

    // *** Page loading styles ***
    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.8);
        z-index: 999;
    }

    .overlay-spinner {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1000;
    }
    // *** End page loading styles ***

    @include media-breakpoint-up(md) {
        .mobile-content {
            display: none;
        }

        .desktop-content {
            display: block;
        }

        .blue-header-container {
            background-color: white;
            padding: 10px 0;
          
            .h3 {
              color: #4c535d;
              font-size: 24px;
            }
        }

        .custom-container {
            padding: 2rem 0rem;
        }

        .custom-mat-card-container {                        
            mat-card {
                border-radius: 4px;
            }
        }
    }
}