@use 'sass:map';
@use '@angular/material' as mat;
@import "./variables";


@mixin button($theme) {

  // *** Apply Site Styles -- Start *** 
  .bc-theme-2024 {
    .mat-flat-button.mat-primary:not(:disabled) {
      color: $balance-white;
      background: linear-gradient(90.61deg, $balance-apply-orange 3.26%, $balance-apply-light-orange 99.47%);
      font-family: 'Lato', sans-serif;
      font-weight: bold;
      border-radius: 10px;
    }

    .mat-stroked-button.mat-primary:not(:disabled) {
      color: $balance-apply-orange;
      background: $balance-white;
      border: 1px solid $balance-apply-orange;
      font-family: 'Lato', sans-serif;
      font-weight: bold;
      border-radius: 10px;
    }
  }

  // *** Apply Site Styles -- End *** 
}

@mixin theme($theme) {
  @include button($theme);
}