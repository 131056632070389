@use '@angular/material' as mat;
@use './input.material.scss' as input;
@use './button.material.scss' as button;
@import '../../../../node_modules/@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// **Be sure that you only ever include this mixin once!**
@include mat.core();

// Define the default theme (same as the example above).
$candy-app-primary: mat.define-palette(mat.$indigo-palette);
$candy-app-accent:  mat.define-palette(mat.$pink-palette, A200, A100, A400);
$candy-app-theme:   mat.define-light-theme($candy-app-primary, $candy-app-accent);

// Include the default theme styles.
@include mat.all-component-themes($candy-app-theme);


// Define an alternate dark theme.
/*$dark-primary: mat-palette($mat-blue-grey);
$dark-accent:  mat-palette($mat-amber, A200, A100, A400);
$dark-warn:    mat-palette($mat-deep-orange);
$dark-theme:   mat-dark-theme($dark-primary, $dark-accent, $dark-warn);*/
$dark-primary: mat.define-palette(mat.$indigo-palette);
$dark-accent:  mat.define-palette(mat.$deep-orange-palette, A700, A100, A400);
$dark-warn:    mat.define-palette(mat.$deep-orange-palette);
$dark-theme:   mat.define-light-theme($dark-primary, $dark-accent);
// Include the alternative theme styles inside of a block with a CSS class. You can make this
// CSS class whatever you want. In this example, any component inside of an element with
// `.unicorn-dark-theme` will be affected by this alternate dark theme instead of the default theme.
/*
.chorus-theme {
  @include angular-material-theme($dark-theme);
}
*/

/*# sourceURL=bc-theme.scss */
@include input.theme($candy-app-theme);
@include button.theme($candy-app-theme);