@use 'sass:map';
@use '@angular/material' as mat;
@import "./variables";


@mixin input($theme) {

  // *** Apply Site Styles -- Start *** 
  .bc-theme-2024 {
    .mat-form-field {
      width: 100%;
    }

    .mat-form-field-underline {
      display: none;
    }

    .mat-form-field-wrapper {
      font-family: 'Lato', sans-serif;
      font-size: 0.9rem;
      border: 1px solid $balance-apply-dark-gray;
      border-radius: 10px;
      padding: 0.7rem 1.5rem !important;
    }

    .mat-focused .mat-form-field-label,
    .mat-form-field-label {
      color: $balance-apply-dark-blue !important;
    }

    .mat-form-field-infix {
      border-top: 0;
    }

    .mat-form-field-optional {
      .mat-form-field-wrapper {
        border: none;
        background-color: #E4E4E44D;

        .mat-focused .mat-form-field-label,
        .mat-form-field-label {
          color: $balance-apply-dark-gray !important;
        }
      }
    }

    .input-error {
      .mat-form-field-wrapper {
        border-color: red;
        color: red;
      }

      .mat-focused .mat-form-field-label,
      .mat-form-field-label {
        color: red !important;
      }
    }
  }

  // *** Apply Site Styles -- End *** 
}

@mixin theme($theme) {
  @include input($theme);
}